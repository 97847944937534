<template>
  <nav class="breadcrumb">
    <ul>
      <li
        v-for="(nav, i) in navs"
        :key="`breadcrumb_nav_${nav.label}`"
        :class="{'is-active': i === navs.length - 1}">
        <router-link :to="nav.path || ''">
          {{ nav.label }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'breadcrumb',
  props: {
    navs: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
nav.breadcrumb {
  margin-bottom: $space-l;
  @extend %display_medium;
}
</style>
